import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BaseCalls} from 'src/objects/BaseCalls';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class ImpersonateService extends BaseCalls {
  constructor(httpClient: HttpClient, private router: Router) {
    super(httpClient);
  }

  public getOrganizations(OrgName?: string) {
    return this.doCall('/admin/organizations/', {params: [{param: 'name', value: OrgName}]});
  }

  public getUsersForOrganization(organizationUUID: string, UserName?: string) {
    return this.doCall('/admin/organization_users/',
      {
        params: [
        {param: 'organization_uuid', value: organizationUUID},
        {param: 'name', value: UserName},
        ]
      });
  }

  public impersonateUser(
    org_user_uuid: string,
    reason: string,
    error_func: any,
    username: string
  ) {
    this.doPostCall(
      '/login/do-impersonate',
      null,
      {params: [{param: 'organization_user_uuid', value: org_user_uuid}, {param: 'reason', value: reason}]}
    ).subscribe((response: any) => {
      if (response.state === 'error') {
        return error_func(response.message);
      }

      let old_token = localStorage.getItem('token');
      let old_username = localStorage.getItem('username');

      localStorage.clear();
      localStorage.setItem('token', response.access_token);
      localStorage.setItem('username', username);
      localStorage.setItem('old_token', old_token ?? '');
      localStorage.setItem('old_username', old_username ?? '');

      this.router.navigate(['/']);
      window.location.reload();
    });
  }
}
