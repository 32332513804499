import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {BaseCalls} from 'src/objects/BaseCalls';

@Injectable({
  providedIn: 'root',
})
export class SwitchOrgService extends BaseCalls {
  constructor(httpClient: HttpClient, private router: Router) {
    super(httpClient);
  }

  public getOrganizationUsers() {
    return this.doCall('/organizationusers/');
  }

  public switchOrganization(org_uuid: string, error_func: any) {
    this.doPostCall(
      '/login/change-organization?organization_uuid=' + org_uuid,
      null
    ).subscribe((response: any) => {
      if (response.state === 'error') {
        return error_func(response.message);
      }

      let old_username = localStorage.getItem('username');

      localStorage.clear();
      localStorage.setItem('token', response.access_token);
      localStorage.setItem('username', old_username ?? '');

      this.router.navigate(['/']);
      window.location.reload();
    });
  }
}
