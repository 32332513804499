import {HttpClient, HttpParams} from '@angular/common/http';
import {map} from 'rxjs/operators';

export abstract class BaseCalls {
  protected baseUrl = '/api/v3';

  /**
   * Sets the secure flag if we are using a https session to authenticate
   * Interceptor adds header -> shared/handlers/interceptor
   * @param httpClient
   */
  constructor(protected httpClient: HttpClient) {
  }

  createHttpParams(params: { param: string, value: any }[]) {
    let httpParams = new HttpParams()
    for (let param of params) {
      if (param.value !== undefined && param.value !== null)
        httpParams = httpParams.set(param.param, param.value)
    }
    return httpParams
  }

  protected doPostCall(url: any, data: any, options?: any) {
    if (options) {
      if (options.params) {
        options.params = this.createHttpParams(options.params)
      }
      return this.httpClient
        .post(this.baseUrl + url, data, options)
        .pipe(map((response: Object) => <any>response));
    }
    return this.httpClient
      .post(this.baseUrl + url, data)
      .pipe(map((response: Object) => <any>response));
  }

  protected doPutCall(url: any, body: any, options?: any) {
    if (options) {
      if (options.params) {
        options.params = this.createHttpParams(options.params)
      }
      return this.httpClient
        .put(this.baseUrl + url, body, options)
        .pipe(map((response: Object) => <any>response));
    }
    return this.httpClient
      .put(this.baseUrl + url, body)
      .pipe(map((response: Object) => <any>response));
  }

  protected doDeleteCall(url: any, options?: any) {
    if (options) {
      if (options.params) {
        options.params = this.createHttpParams(options.params)
      }
      return this.httpClient
        .delete(this.baseUrl + url, options)
        .pipe(map((response: Object) => <any>response));
    }
    return this.httpClient
      .delete(this.baseUrl + url)
      .pipe(map((response: Object) => <any>response));
  }

  protected doCall(url: any, options?: any) {
    if (options) {
      if (options.params) {
        options.params = this.createHttpParams(options.params)
      }
      return this.httpClient
        .get(this.baseUrl + url, options)
        .pipe(map((response: Object) => <any>response));
    }
    return this.httpClient
      .get(this.baseUrl + url)
      .pipe(map((response: Object) => <any>response));
  }
}
